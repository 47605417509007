import React, { useEffect, useState } from "react";
import theme from "../styles/theme";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";

const PdfEmbed = ({ data }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  });

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handlePageNumber = (e, dir) => {
    e.preventDefault();
    if (dir === "prev" && pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
    if (dir === "next" && pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  return (
    <>
      <h2>{data.title}</h2>

      <div
        css={`
          position: relative;
          flex-direction: column;
          .pdfBox .pdfPageBox canvas {
            width: 100% !important;
            height: auto !important;
            opacity: 1;
            transition: opacity 0.4s;
            box-shadow: 4px 4px 20px rgb(0 0 0 / 20%);
          }
          .pdfBox.loading {
            opacity: 0;
          }
          .passive {
            opacity: 0.2;
            pointer-events: none;
            cursor: default;
          }
          .pdfBox {
            position: absolute;
            top: 0;
          }
          .a4 {
            width: 100%;
            padding-top: 70%;
          }
        `}
      >
        <div className="a4">
          <Document
            className={`pdfBox ${loading ? "loading" : "success"}`}
            file={data.pdf}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page
              className="pdfPageBox"
              pageNumber={pageNumber}
              loading={() => setLoading(true)}
              onLoadSuccess={() => setLoading(false)}
            />
          </Document>
        </div>

        <div
          css={`
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 20px;
            z-index: 1;
            position: relative;
            .center {
              font-size: 15px;
              display: flex;
              flex-direction: column;
              text-align: center;
            }
            a {
              margin-top: 10px;
              text-decoration: underline;
            }
            button {
              margin: 20px;
            }
          `}
        >
          <button
            className={pageNumber > 1 ? "active" : "passive"}
            onClick={e => handlePageNumber(e, "prev")}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20 11H7.83L13.42 5.41L12 4L4 12L12 20L13.41 18.59L7.83 13H20V11Z"
                fill={theme.pink}
              />
            </svg>
          </button>
          <div className="center">
            <span>
              Page {pageNumber} of {numPages}
            </span>
            <a href={data.pdf} target="_blank" rel="noreferrer">
              Open PDF
            </a>
          </div>
          <button
            className={pageNumber < numPages ? "active" : "passive"}
            onClick={e => handlePageNumber(e, "next")}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 4L10.59 5.41L16.17 11H4V13H16.17L10.59 18.59L12 20L20 12L12 4Z"
                fill={theme.pink}
              />
            </svg>
          </button>
        </div>
      </div>
    </>
  );
};

export default PdfEmbed;
